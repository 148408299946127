<template>
  <v-skeleton-loader
    type="image"
    class="address"
    v-if="!value"
  />
  <v-card
    class="address"
    v-else
  >
    <v-card-title>{{$t('t.Address')}}</v-card-title>
    <v-card-text>
      <div class="d-flex flex-column">
        <v-text-field
          :label="$t('t.Address1')"
          v-model="computedAddress1"
          class="address1"
        />
        <v-text-field
          :label="$t('t.Address2')"
          v-model="computedAddress2"
          class="address2"
        />
        <v-text-field
          :label="$t('t.Address3')"
          v-model="computedAddress3"
          class="address3"
        />
        <div class="d-flex flex-row">
          <v-text-field
            :label="$t('t.City')"
            v-model="computedCity"
          />
          <v-text-field
            :label="$t('t.StateProvince')"
            v-model="computedStateProvince"
          />
          <v-text-field
            :label="$t('t.PostalCode')"
            v-model="computedPostalCode"
          />
          <v-text-field
            :label="$t('t.Country')"
            v-model="computedCountry"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataAddress1: null,
      dataAddress2: null,
      dataAddress3: null,
      dataCity: null,
      dataCountry: null,
      dataPostalCode: null,
      dataStateProvince: null,
      minLength: [v => !v || v.length === 0 || v.length >= 3 || this.$t('t.MinLength', { value: 3 })]
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        address1: this.dataAddress1,
        address2: this.dataAddress2,
        address3: this.dataAddress3,
        city: this.dataCity,
        country: this.dataCountry,
        postalCode: this.dataPostalCode,
        stateProvince: this.dataStateProvince
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    },
    maxLength (max) {
      return [v => !v || v.length <= max || this.$t('t.MaxLength', { value: max })]
    }
  },
  computed: {
    computedAddress1: {
      get () { return this.dataAddress1 },
      set (v) {
        this.dataAddress1 = v
        this.dataDocumentEmit()
      }
    },
    computedAddress2: {
      get () { return this.dataAddress2 },
      set (v) {
        this.dataAddress2 = v
        this.dataDocumentEmit()
      }
    },
    computedAddress3: {
      get () { return this.dataAddress3 },
      set (v) {
        this.dataAddress3 = v
        this.dataDocumentEmit()
      }
    },
    computedCity: {
      get () { return this.dataCity },
      set (v) {
        this.dataCity = v
        this.dataDocumentEmit()
      }
    },
    computedCountry: {
      get () { return this.dataCountry },
      set (v) {
        this.dataCountry = v
        this.dataDocumentEmit()
      }
    },
    computedPostalCode: {
      get () { return this.dataPostalCode },
      set (v) {
        this.dataPostalCode = v
        this.dataDocumentEmit()
      }
    },
    computedStateProvince: {
      get () { return this.dataStateProvince },
      set (v) {
        this.dataStateProvince = v
        this.dataDocumentEmit()
      }
    },
    length () {
      return [...this.minLength, ...this.maxLength(200)]
    }
  },
  props: {
    value: Object
  },
  watch: {
    value: {
      handler (v) {
        this.dataAddress1 = v?.address1
        this.dataAddress2 = v?.address2
        this.dataAddress3 = v?.address3
        this.dataCity = v?.city
        this.dataCountry = v?.country
        this.dataPostalCode = v?.postalCode
        this.dataStateProvince = v?.stateProvince
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.border-b
  border-bottom 1px #00000020 solid

.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.address
  min-width 10em

.address1, .address2, .address3
  width 60em

.v-text-field
  margin-right 1em
</style>
